<template>
  <div class="seventh" :class="{ 'close-anim': closeAnim }">
    <RFModal v-if="isShowModal" @close="close">
      <div class="slider">
        <vueper-slides
          ref="vueperslides1"
          :touchable="false"
          fade
          :autoplay="false"
          :bullets="false"
          class="slider-big"
          fixed-height="100%"
        >
          <template #arrow-left>
            <Icons icon-name="arrow-left" />
          </template>

          <template #arrow-right>
            <Icons icon-name="arrow-right" />
          </template>
          <vueper-slide
            v-for="(slide, i) in slides"
            :key="i"
            :image="slide.image"
            class="slider-big__slide"
          >
          </vueper-slide>
        </vueper-slides>
      </div>
    </RFModal>
    <div class="seventh-inner container">
      <section class="seventh-left">
        <h2 class="seventh-left__title wow" data-wow-offset="300">
          Дизайнерские холлы
        </h2>
        <img
          @click="openModal(7)"
          class="seventh-left__img--1 onlyTabletMax wow"
          data-wow-offset="250"
          alt="фото холла"
          :src="require('@/assets/images/seventh/8.webp')"
        />
        <div class="seventh-left-bottom">
          <!-- ! версия для десктопа с картинками -->
          <img
            @click="openModal(5)"
            data-wow-offset="250"
            alt="фото холла"
            class="seventh-left-bottom__img--2 onlyTabletMax wow"
            :src="require('@/assets/images/seventh/6.webp')"
          />
          <img
            @click="openModal(6)"
            data-wow-offset="250"
            alt="фото холла"
            class="seventh-left-bottom__img--3 onlyTabletMax wow"
            :src="require('@/assets/images/seventh/7.webp')"
          />
          <!-- ! / версия для десктопа с картинками -->
          <!-- ! версия со скролом для планшетов и мобилок -->
          <img
            v-for="(slide, i) in slides"
            :key="i"
            @click="openModal(i)"
            :src="slide.image"
            alt="фото холла"
            class="onlyTabletMin seventh-left-bottom__img wow"
            data-wow-offset="200"
          />
        </div>
      </section>
      <div class="seventh-right">
        <div class="seventh-right-top">
          <!-- ! версия для десктопа с картинками -->
          <img
            @click="openModal(10)"
            data-wow-offset="300"
            alt="фото холла"
            class="seventh-right-top__img--5 onlyTabletMax wow"
            :src="require('@/assets/images/seventh/11.webp')"
          />
          <img
            @click="openModal(9)"
            data-wow-offset="300"
            alt="фото холла"
            class="seventh-right-top__img--6 onlyTabletMax wow"
            :src="require('@/assets/images/seventh/10.webp')"
          />
          <!-- ! / версия для десктопа с картинками -->
        </div>
        <section class="seventh-right-bottom wow">
          <h3 class="seventh-right-bottom__title">
            Дизайнерское решение холлов <br />не должно оставить вас равнодушным
          </h3>
          <p class="seventh-right-bottom__text">
            Ведь это первое место, куда необходимо зайти, прежде чем попасть в
            вашу квартиру мечты
          </p>
          <p class="seventh-right-bottom__text">
            Мы постарались сделать все, чтобы вы испытали настоящее эстетическое
            удовольствие. Именитые дизайнеры работали над изяществом деталей и
            необычностью фактур, поэтому будьте уверены, каждая деталь сделана с
            огромной любовью
          </p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import RFModal from '@/components/atoms/Modals/RFModal.vue'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import Icons from '@/components/atoms/Icons/index.vue'
import { WOW } from 'wowjs'

export default {
  name: 'SeventhSection',
  components: { RFModal, Icons, VueperSlides, VueperSlide },
  data() {
    return {
      isShowModal: false,
      closeAnim: false,
      slides: [
        {
          image: require('@/assets/images/seventh/1.webp'),
        },
        {
          image: require('@/assets/images/seventh/2.webp'),
        },
        {
          image: require('@/assets/images/seventh/3.webp'),
        },
        {
          image: require('@/assets/images/seventh/4.webp'),
        },
        {
          image: require('@/assets/images/seventh/5.webp'),
        },
        {
          image: require('@/assets/images/seventh/6.webp'),
        },
        {
          image: require('@/assets/images/seventh/7.webp'),
        },
        {
          image: require('@/assets/images/seventh/8.webp'),
        },
        {
          image: require('@/assets/images/seventh/9.webp'),
        },
        {
          image: require('@/assets/images/seventh/10.webp'),
        },
        {
          image: require('@/assets/images/seventh/11.webp'),
        },
      ],
    }
  },
  mounted() {
    var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
    })
    wow.init()
  },
  watch: {
    isShowModal: {
      handler(val) {
        this.$store.commit('Modals/updateStatus', val)
      },
    },
  },
  methods: {
    openModal(i) {
      this.isShowModal = true
      // фикс ошибки (модалка не успевала открываться)
      setTimeout(() => {
        this.$refs.vueperslides1.goToSlide(i)
      }, 1)
    },
    close() {
      this.closeAnim = true
      setTimeout(() => {
        this.closeAnim = false
        this.isShowModal = !this.isShowModal
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  padding-right: 0;
}
.seventh {
  width: 100%;
  position: relative;
  height: fit-content;

  img {
    cursor: pointer;
  }

  &-inner {
    justify-content: space-between;
    gap: 30px;
    @include flex-row();

    @include tablet-portrait {
      flex-direction: column;
      justify-content: flex-start;
    }
    @include mobile {
      gap: 0;
    }
  }

  &-left {
    &__title {
      animation: manifestation1 0.8s ease-in;
      padding-bottom: 44px;
      @include font-styles(48px, 56px, 700, $c-dark, 0, $font-third);

      @include tablet-portrait {
        padding-bottom: 0;
      }
      @include mobile {
        @include font-styles(28px, 32px, 700, $c-dark, 0, $font-third);
      }
    }
    &__img {
      background-size: cover;
      &--1 {
        max-width: 620px;
        height: 586px;
        border-radius: 40px 120px;
      }
    }
    &-bottom {
      padding-top: 36px;
      height: 318px;
      gap: 40px;
      justify-content: flex-end;
      @include flex-row();

      @include tablet-portrait {
        height: fit-content;
        gap: 10px;
        padding-top: 16px;
        justify-content: flex-start;
        width: fit-content;
        overflow-x: scroll;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__img {
        background-size: cover;

        @include tablet-portrait {
          width: 150px;
          height: 200px;

          &:nth-child(odd) {
            border-radius: 60px 20px 20px 20px;
          }
          &:nth-child(even) {
            border-radius: 20px 20px 20px 60px;
          }
        }
        &--1 {
          animation: manifestation1 0.8s ease-in;
        }
        &--2 {
          animation: manifestation1 0.8s ease-in;
          max-width: 214px;
          align-self: flex-end;
          height: 202px;
          border-radius: 40px 80px 40px 40px;
        }
        &--3 {
          animation: manifestation1 0.8s ease-in;
          max-width: 262px;
          height: 248px;
          border-radius: 80px 40px 40px 40px;
        }
      }
    }
  }
  &-right {
    &-top {
      padding-top: 20px;
      height: fit-content;
      justify-content: space-between;
      align-items: flex-end;
      gap: 50px;
      @include flex-row();

      &__img--5 {
        animation: manifestation1 0.8s ease-in;
        width: 260px;
        height: 248px;
        border-radius: 80px 40px 40px 40px;
      }
      &__img--6 {
        animation: manifestation1 0.8s ease-in;
        width: 412px;
        height: 390px;
        border-radius: 80px 40px;
      }
    }
    &-bottom {
      animation: manifestation1 0.8s ease-in;
      height: 100%;
      margin: 0 auto;
      max-width: 430px;
      gap: 30px;
      max-height: 590px;
      justify-content: center;
      @include flex-column();

      @include tablet-portrait {
        max-height: none;
        max-width: 100vw;
        padding-right: 86px;
      }

      @include mobile {
        gap: 16px;
        padding-right: 15px;
      }

      &__title {
        @include font-styles(38px, 46px, 700, $c-dark, 0, $font-third);

        @include mobile {
          @include font-styles(18px, 22px, 700, $c-dark, 0, $font-third);
        }
      }
      &__text {
        @include font-styles(18px, 24px, 400, $c-gray, 0, $font-second);

        @include mobile {
          text-align: left;
          @include font-styles(14px, 20px, 400, $c-gray, 0, $font-second);
        }
      }
    }
  }
}

.slider {
  // фикс бага отображения undefined на карточках
  font-size: 0;
  width: 540px;
  padding-bottom: 10px;

  @include mobile {
    background-color: $white !important;
    width: calc(100vw - 30px);
  }

  &-big {
    font-size: 0;
    border-radius: 40px;
    overflow: hidden;
    width: 1200px !important;
    height: 700px !important;

    @include tablet-portrait {
      width: calc(100vw - 50px) !important;
      height: 700px !important;
    }

    @include mobile {
      width: calc(100vw - 30px) !important;
      height: 480px !important;
      border-radius: 20px;
    }

    &__slide {
      height: 100%;
    }
  }

  &__title {
    padding-bottom: 5px;
    display: inline-block;
    @include font-styles(26px, 36px, 600);

    @include mobile {
      @include font-styles(16px, 20px, 600, $c-dark);
    }
  }
}

// * Стили для стрелок

::v-deep .vueperslides__arrow {
  opacity: 0.9;
  &--prev {
    left: 32px;
    @include mobile {
      left: 10px;
    }
  }
  &--next {
    right: 32px;
    @include mobile {
      right: 10px;
    }
  }
}

::v-deep .modal__body {
  border: none;
}
</style>
